import { CSSProperties, useEffect } from 'react';
// import { useSwipeable } from "react-swipeable";
import LazyLoad from 'react-lazyload';
import './App.css';
import contentData from './content.json';
import {VideoSrc} from './types';

interface VideoAssetProps {
  asset: {
    src: VideoSrc
  }
  assetStyle: CSSProperties
}

function VideoAsset({asset, assetStyle}: VideoAssetProps) {
  // useEffect(() => {
  //   console.debug(`mounted ${asset.src.mp4}`)
  //   return () => {
  //     console.debug(`unmounted ${asset.src.mp4}`)
  //   }
  // }, [asset])
  return (
    <video muted={true} loop={true} autoPlay={true} playsInline={true} style={assetStyle} poster={asset.src.poster}>
      <source src={asset.src.webm} type="video/webm; codecs=vp8,vorbis" />
      <source src={asset.src.mp4} type="video/mp4" />
    </video>
  )
}


function Asset({asset}) {
  /*
    Dom handling

    Size by asset ratio
      ctxClass: 'aspect'
      ctxStyle: paddingBottom: '%'

    Asset dom style override
      style:
  */
  let assetdom
  // let assetName = projectTitle + ' ' + (idx+1)

  const parentStyle = asset.parentStyle || {}
  const assetStyle = asset.style || {}
  const ctxStyle = asset.ctxStyle || {}
  const ctxClass = asset.ctxClass || ''

  // console.log(asset.src, asset);

  if (asset.type === 'image' || asset.type === 'scroll') {
    // Image
    assetdom = (
      <LazyLoad offset={200} resize={true}>
        <img src={asset.src} alt={asset.name} style={assetStyle} />
      </LazyLoad>
    )
  } else if (asset.type === 'video') {
    // Video
    assetdom = (
      <LazyLoad offset={200} unmountIfInvisible={true} resize={true}>
        <VideoAsset {...{ asset, assetStyle }} />
      </LazyLoad>
    )
  }

  // assetdom = <div></div>

  // Context div layer used for things like aspect ratio sizing
  // class: .aspect
  const ctx = (
    <div className={ctxClass} style={ctxStyle}>
      {assetdom}
    </div>
  )

  // Another div wrapper layer for the chrome
  let chrome
  if (asset.chrome) {
    chrome = (
      <div className={"chrome " + asset.chrome}>
        <div>
          {ctx}
        </div>
      </div>
    )
  } else {
    chrome = ctx
  }

  return (
    <li key={asset.src} style={parentStyle}>
      {chrome}
    </li>
  )
}


const text = text => {
  if (typeof text === 'string') text = [text]
  return (
    <>
      {text.map((text, i) => <p key={i} className="text">{text}</p>)}
    </>
  )
}

const Info = ({project, classVariant = ''}) => {
  return (
    <div className={'info '+classVariant}>
      <div>
        <h3>{project.title}</h3>
        {text(project.text)}
        {(project.role || project.company) && (
          <p className='role'>
            {project.role} 
            {project.company && <> @{project.company}</>}
          </p>
        )}
        {project.tech && (
          <p className='tech'>
            {project.tech.map((t, idx) => <span key={idx}>{t}</span>)}
          </p>
        )}
      </div>
    </div>
  )
}

function HarleyAssets({project}) {
  return (
    <>
      <ul className="preview n">
        {project.assets.map((asset, idx) => <Asset key={asset.name} asset={asset} />)}
      </ul>
      <ul className="preview w">
        <li>
          <ul>
            <Asset asset={project.assets[0]} />
            <Asset asset={project.assets[1]} />
          </ul>
        </li>
        <li>
          <ul>
            <Asset asset={project.assets[2]} />
            <Asset asset={project.assets[3]} />
          </ul>
        </li>
      </ul>
    </>
  )
}

function Project({project}) {
  return (
    <li key={project.key} className={`project-${project.key}`}>
      {Info({ project, classVariant: 'w' })}
        {(() => {
          switch (project.key) {
            case 'harley':
              return <HarleyAssets project={project} />
            default:
              return (
                <ul className="preview">
                  {project.assets.map((asset, idx) => <Asset key={asset.name} asset={asset} />)}
                </ul>
              )
          }
        })()}
      {Info({ project, classVariant: 'n' })}
    </li>
  )
}

function Projects({projects}) {
  return (
    <ul className="projects">
      {projects.map(project => (
        <Project key={project.key} project={project} />
      ))}
    </ul>
  )
}

// const jump = idx => {
//   // todo duration https://www.npmjs.com/package/scroll-js
//   window.scrollTo({
//     top: (document.querySelectorAll('.projects > li')[idx] as HTMLElement).offsetTop, 
//     // todo + top page offset
//     behavior: 'smooth'
//   })
// };

// function Nav({projects}) {
//   return (
//     <nav>
//       <ul>
//         {projects.map((project, idx) => (
//           <li key={project.key}>
//             <button onClick={() => jump(idx)}>
//               {/* {project.title} */}
//               --
//             </button>
//           </li>
//         ))}
//       </ul>
//     </nav>
//   );
// }

function App() {
  return (
    <div className="frame">
      <div>
        <header>
          <h1>Adam Suharja</h1>
        </header>
        {/* <Nav projects={contentData.projects} /> */}
      </div>
      <Projects projects={contentData.projects} />
      <footer>
        <span>2021</span>
      </footer>
    </div>
  );
}

export default App;
